.height-2px {
  height: 2px;
}

.height-10px {
  height: 10px;
}

.height-5px {
  height: 5px;
}

.height-4px {
  height: 4px;
}

.height-16px {
  height: 16px;
}

.height-40px {
  height: 40px;
}
.height-45px {
  height: 45px;
}

.height-80px {
  height: 80px;
}

.height-120px {
  height: 120px;
}

.height-150px {
  height: 150px;
}

.height-180px {
  height: 180px;
}

.height-200px {
  height: 200px;
}
.height-350px {
  height: 350px;
}

.width-1px {
  width: 1px;
}

.width-2px {
  width: 2px;
}

.width-16px {
  width: 16px;
}

.height-width-44px {
  height: 44px;
  width: 44px;
}
.height-width-144px {
  height: 144px;
  /* width: 144px; */
}

.delete-icon-position {
  transform: translate(75px, -48px);
}

.background-color-4E4E4E {
  background-color: #4e4e4e;
}

.background-color-E5E5E5 {
  background-color: #e5e5e5;
}

.border-2px-E5E5E5 {
  border: 2px solid #e5e5e5;
}

.transform-translate_0px_-5px {
  transform: translate(0px, -5px);
}

.transform-translate_0px_-10px {
  transform: translate(0px, -10px);
}

.transform-translate_0px_-15px {
  transform: translate(0px, -15px);
}

.transform-translate_0px_-28px {
  transform: translate(0px, -28px);
}

.rotate-90 {
  transform: rotate(-90deg);
}

.right-20px {
  right: 70px;
}

.top-10px {
  top: 10px;
}

.top-25px {
  top: 25px;
}

.border-radius-8px {
  border-radius: 8px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.text-underline-hover:hover {
  text-decoration: underline;
}
