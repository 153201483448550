.top-6px {
  top: 6px;
}

.right-10px {
  right: 10px;
}

.height-1px {
  height: 1px;
}

.transform-rotate-270deg {
  transform: rotate(270deg);
}

.border-bottom-1px-e5e5e5 {
  border-bottom: 1px solid #e5e5e5;
}

.completed-text-color {
  color: #959595;
}

.height-8px {
  height: 8px;
}

.padding-top-20-percent {
  padding-top: 50%;
}

.width-125px {
  width: 100px;
}

.z-index-1000 {
  z-index: 1000;
}

.carousel-remaining-height {
  height: calc(100% - 20px);
}

.carousel-slider {
  height: 100%;
}
.slider-wrapper {
  height: 100%;
}
.slider {
  height: 100%;
}
.slide {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.slide::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/* --- media queries --- */
@media screen and (min-width: 850px) {
  .padding-top-20-percent {
    padding-top: 10%;
  }
}
