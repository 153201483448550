.height-50-px {
  height: 50px;
}

.swipeButton,
.swipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.background-color-f1f1f1 {
  background: #f1f1f1;
}

.swipeButtonLocked {
  width: 100% !important;
  transition: 0.5s;
  cursor: default;
}

.swipeButtonLocked .slider {
  left: 100% !important;
  cursor: default;
  pointer-events: none;
}

.swipeButtonLocked .arrowIcon {
  transition: 0.5s;
  margin-right: -60px;
}
.swipeButtonLocked .sliderCircle {
  transition: 0.5s;
  margin-right: -60px;
}
.slider {
  position: absolute;
  top: 0;
  margin-left: -100%;
  border-radius: 8px;
  z-index: 100;
  cursor: pointer;
}
.sliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
}

.right-20-px {
  right: 20px;
}

.right-30-px {
  right: 30px;
}

.arrowIcon {
  position: absolute;
  transform: rotate(45deg);
  border: 2px solid #fff;
  height: 8px;
  width: 8px;
  top: 50%;
  margin-top: -6px;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform-origin: center;
  z-index: 10;
}

.sliderCircle {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  border-radius: 8px;
}
