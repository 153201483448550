.height-224px {
  max-height: 224px;
}

.remaining-body-height-home {
  height: calc(100% - 63.5px);
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
