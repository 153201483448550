.nav-bar-box-shadow {
  box-shadow: 0px -12px 24px #ffffff;
}

.nav-bar-width {
  width: calc(100% + 1px);
}

@media screen and (min-width: 850px) {
  .large-screen-border-for-navigation-bar {
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
}
