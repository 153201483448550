.border-bottom-1px-lite-grey {
  border-bottom: 1px solid #e5e5e5;
}

.height-2px {
  height: 2px;
}

.border-position-bottom {
  transform: translate(0px, 26px);
}

.background-color-pending {
  background: rgba(166, 169, 0, 0.12);
}

.background-color-accepted {
  background: rgba(0, 160, 0, 0.08);
}

.background-color-rejected {
  background: rgba(230, 69, 57, 0.08);
}

.font-color-pending {
  color: #a6a900;
}
