.height-180px {
  height: 186px;
}

.height-120px {
  height: 150px;
}

.height-width-48px {
  height: 48px;
  width: 48px;
}

.rotate-180 {
  transform: rotate(180deg);
}
